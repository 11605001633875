<template>
  <div class="sellBoard" v-loading="loading">
    <div class="queryBox dp_f">
        <div class="board-query dp_f">
          <el-select
            placeholder="请选择仓库"
            class="no-boder-select"
            v-model="formData.stockId"
            popper-class="no-border-select-popper"
          >
            <el-option
              v-for="item in sotckList"
              :key="item.stockId"
              :label="item.stockName"
              :value="item.stockId"
            />
          </el-select>
          <div class="timeSelect">
            <div
              class="item cur_p"
              :class="tabChecked === dateType.year ? 'active' : ''"
              @click="tabCheck(dateType.year)"
            >
              年维度
            </div>
            <div
              class="item cur_p"
              :class="tabChecked === dateType.month ? 'active' : ''"
              @click="tabCheck(dateType.month)"
            >
              月维度
            </div>
            <div
              class="item cur_p"
              :class="tabChecked === dateType.day ? 'active' : ''"
              @click="tabCheck(dateType.day)"
            >
              日维度
            </div>
        </div>
      </div>
         <div class="btnBox dp_f">
          <el-date-picker
            v-model="formData.time"
            v-if="!isYear"
            :type="timeType"
            unlink-panels
            class="w280 mr_16"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="large"
            :clearable="false"
            :disabled-date="isOverToday"
            @change="changeTime"
          />
           <el-date-picker
               v-model="formData.yearTime"
               v-else
               type="year"
               unlink-panels
               class="w280 mr_16"
               placeholder="请选择年份"
               size="large"
               :disabled-date="isOverToday"
               @change="changeYear"
               :clearable="false"
           />
           <search-btn @click="showLoading" class="square"></search-btn>
           <export-btn :form-data="formData" type="profit" :list-length="tableData.length"></export-btn>
        </div>
    </div>
    <div class="boardContent">
      <component
        :is="item.component"
        v-model:formData="formData"
        v-for="item in tabList"
        :key="item.timer"
        @changeList="getList"
      ></component>
    </div>
  </div>
</template>
<script setup>
import {computed, defineAsyncComponent, getCurrentInstance, onMounted, reactive, ref, shallowRef, toRefs} from "vue";
import moment from "moment";
import {stockList} from "@/utils/api/procurement/stock.js";
import ExportBtn from "@components/exportBtn/index.vue";
import SearchBtn from "@components/element/searchBtn.vue";
import {isOverToday} from "@utils/tool.js";
import {dateType} from './config'

const {proxy} = getCurrentInstance();
const loading = ref(false);
const state = reactive({
  tabChecked: -1,
  formData: {
    time: [],
    stockId: "",
    yearTime: "",
    timeType: 0,
  },
  sotckList: [],
  isLike: true,
  timeType: 'monthrange',
});
const timeTypeConfig = {
  2: 'monthrange',
  1: 'daterange'
}
const isYear = computed(() => {
  return state.tabChecked === dateType.year;
});
const {tabChecked, formData, sotckList, isLike, timeType} = toRefs(state);
const getStockListData = async () => {
  const res = await stockList();
  if (res.code === 0) {
    if (res.data?.list?.length === 1) {
      state.formData.stockId = res.data.list[0].stockId;
      state.sotckList = res.data.list;
    } else if (res.data?.list?.length && res.data?.list?.length > 1) {
      state.sotckList.push({stockId: "", stockName: "全部仓库"});
      state.sotckList.push(...res.data.list);
    }
  } else {
    proxy.$message.error(res.msg);
  }
};
const tableData = ref([]);
const changeYear = (e) => {
  formData.value.yearTime = moment(e).format('yyyy-MM-DD')
  formData.value.time = [
    moment(e).startOf('year').format("YYYY-MM-DD"),
    moment(e).endOf('year').format("YYYY-MM-DD"),
  ];
}
const getList = (e = []) => {
  tableData.value = e || []
}
//切换本月年度
const tabCheck = (num) => {
  if (num === state.tabChecked) return;
  formData.value.timeType = num;
  clearTime();

  switch (num) {
    case dateType.year:
      //设置formData的time和yearTime
      formData.value.yearTime = moment().format('yyyy-MM-DD')
      formData.value.time = [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
      break;
    case dateType.month:
      formData.value.time = [
        moment().startOf("months").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
      break;
    case dateType.day:
      formData.value.time = [
        moment().startOf("months").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
      break;
    default:
      break;
  }
  tabChecked.value = num;
  timeType.value = timeTypeConfig[num]
};

//清空formData的time和yearTime
const clearTime = () => {
  formData.value.time = [];
  formData.value.yearTime = "";
};

const showLoading = () => {
  tabList.value.forEach((item) => {
    item.timer = new Date().getTime();
  });
};

const changeTime = (e) => {
  if (!e) return
  if (tabChecked.value === dateType.day) {
    formData.value.time = [
      moment(e[0]).format("yyyy-MM-DD"),
      moment(e[1]).format("yyyy-MM-DD"),
    ];
  } else {
    formData.value.time = [
      moment(e[0]).format("YYYY-MM-DD"),
      moment(e[1]).endOf('month').format("YYYY-MM-DD"),
    ];
  }

};
const tabList = ref([{
  component: shallowRef(defineAsyncComponent(() => import('./components/board.vue'))),
  timer: 0
}]);

//获取今天的日期
onMounted(() => {
  getStockListData();
  tabCheck(dateType.day)
});
</script>
<script>
export default {
  name: "sellBoard",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.select {
  margin: 0 8px;
  width: 160px;
}
</style>
